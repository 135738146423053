import { Component, AfterViewInit, OnInit } from '@angular/core';
import { AlertController, ToastController, isPlatform } from '@ionic/angular';
 import { AuthService } from './services/auth/auth.service';
import {  NavigationEnd, Router } from '@angular/router';
import { DataLayerService } from './services/data-layer/data-layer.service';
import { IdbService } from './services/indexDB/idb.service';
import { SyncService } from './services/sync/sync.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { UpdateQueueService } from './services/update-queue/update-queue.service';
import { releaseInfo } from './release-info.config';
import { environment } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';
import { SwUpdate, UnrecoverableStateEvent, VersionReadyEvent } from '@angular/service-worker';
import { filter, map, tap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { FileService } from './services/file/file.service';
import { Location } from '@angular/common';
 

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {

  user: any = null;
  PWAev: any;
  serverInfo: string = "";

  appSectionsBase = [
    { title: 'Dashboard', url: '/dashboard', icon: 'apps-outline' },
    { title: 'Jobs List', url: '/job-list', icon: 'file-tray-full-outline' },
    { title: 'New ER Job', url: '/jobs/new', icon: 'flash-outline' },
    { title: 'TimeSheet', url: '/ts-calendar', icon: 'receipt-outline' },
    // { title: 'Documents List', url: '/docs-list', icon: 'documents-outline' },  //this is now broken into subset folders
    { title: 'File Sync', url: '/file-sync', icon: 'sync-outline' },
    // { title: 'Log In', url: '/login', icon: 'log-in-outline' },  //it should get here automatically.
    { title: 'Queue list', url: 'queue-list', icon: 'cloud-upload-outline' },
    { title: 'Update App', url: '', icon: 'refresh-outline' },
    { title: 'Device Details', url: '/device', icon: 'log-in-outline' },

    { title: 'Log Out', url: '', icon: 'log-out-outline' },
    //{ title: 'Install App', url: '', icon: 'add-outline' },
  ];

  appSections = this.appSectionsBase;
  netWorkStatus: boolean = true;
  private fileScanner: Subscription;
  private folderScanner: Subscription;

  showLoader: boolean = true;

  event$
  constructor(
    private auth: AuthService,
    private dl: DataLayerService,
    private router: Router,
    private idb: IdbService,
    private sync: SyncService,
    private fs: FileService,
    private uQueue: UpdateQueueService,
    private toastController: ToastController,
    private swUpdate: SwUpdate,
    private alertController: AlertController,
    private location:Location
   ) {
    const routePath = this.location.path()
    
    this.event$ = this.location.onUrlChange((val) => {
      //console.log('url -d '+ val)
    })
    // console.log('url', routePath);
    // console.log('RELEASE INFO', releaseInfo);
    // console.log('ENVIRONMENT', environment)

    let authClient = environment.authorizationClient;

    this.getServer();
    /* Double Check Server In Case of Bad Release */
    if (!this.dl.apiServer) {
      this.serverInfo = "NO SERVER FOUND";
    } else {
      if (this.dl.apiServer.indexOf('pinnacle.apidas') > 0) {
        this.serverInfo = "PRODUCTION SERVER"
      } else {
        this.serverInfo = "DEVELOMENT SERVER"
        if (this.dl.apiServer.indexOf('pwa.morrisdev.com') > 0) {
          this.dl.apiServer = 'https://server19.apidas.com';
        }
      }
    }
    this.dl.checkOnlineNetwork();
    console.warn('checking updates');
    this.checkForAppUpdates();
    console.log(navigator.userAgent);

    
  }

  

  async ngOnInit() {
    this.showLoader = false;
    // setTimeout(() => {
    //   this.showLoader = false;
    // }, 3500);
  }
  

  async ngAfterViewInit() {

    await this.idb.initializeDB();  //make sure the database is initialized
    await this.uQueue.init();       //start the queue interval service
    await this.sync.init();         //start the sync service for jobs

    //TODO: add FILE sync here
    console.log('startFileSync')
    await this.startFileSync();

    this.dl.loadReportTemplates();

    //this.promptPWAInstallation();
    //this.getForemansProfile();
  }

  async ionViewDidEnter() {
    
    await SplashScreen.hide();
    this.dl.online$.subscribe(res => {
      this.netWorkStatus = res;
      console.log("networkStatus => ", this.netWorkStatus, this.dl.online)
    })
    
  }

  async loadUser() {
    this.user = this.auth.getUser();
    
    console.log("local user =>", this.user);
    if (this.dl.online) {
      if (this.user === null) {
        this.router.navigate(['/login']);
      } else {
        this.loadMenuData();
      }
    } else {
      this.offlineLegend();
      console.log("system offline...");
      this.loadMenuData();
    }
    
  }
  // async loadUser() {
  //   if (this.dl.online) {
  //     this.user = this.auth.getUser();
  //     console.log("local user =>", this.user);
  //     if (this.user === null) {
  //       this.router.navigate(['/login']);
  //     } else {
  //       this.loadMenuData();
    
  //       //let dashMenu = this.dl.dynamicMenuData.getValue();
  //     }
  //   } else {
  //     this.offlineLegend();
  //     console.log("system offline... getting local sessionID ")
  //     this.auth.getLocalSessionID();
  //   }
    
  // }

  loadMenuData() {
    this.dl.dynamicMenuData.subscribe((dashMenu: any) => {
      const lastTwoItemsIndex = Math.max(0, this.appSectionsBase.length - 2);
      const uniqueUrls = new Set(this.appSectionsBase.map(itemA => itemA.url));
      const updatedSections = [...this.appSectionsBase]; // Start with appSectionsBase

      dashMenu.forEach(item => {
        if (!uniqueUrls.has('/' + item.url)) {
          item.icon = 'chevron-forward-outline';
          updatedSections.splice(lastTwoItemsIndex, 0, { ...item });
          uniqueUrls.add(item.url);
        }
      });
      this.appSections = updatedSections; // Update the reference
      //console.log(this.appSections);
    });
  }

  async startFileSync() {

    if (this.netWorkStatus) { 
      await this.fs.refreshFolders();
      this.fs.processFolders();
    }

    //await this.fileScanInterval();
    //await this.folderScanInterval();


  }
  async folderScanInterval() {

    const ThirtySec = 300000;
    const source = interval(ThirtySec);
    this.folderScanner = source.subscribe(async () => {

      if (this.netWorkStatus)
        await this.fs.refreshFolders();
        this.fs.processFolders
    });
  }

  // async fileScanInterval() {
  //   const deviceSerial = this.auth.currentDeviceSerial.value;     
  //   const fiveMinutesInterval = 30000;
  //   const source = interval(fiveMinutesInterval);
  //   this.fileScanner = source.subscribe(() => {
  //     if(this.netWorkStatus)
  //       this.dl.getTrackedAssetsByDeviceSerial(deviceSerial)
  //   });
  // }

  ngOnDestroy(): void {
    if (this.fileScanner) this.fileScanner.unsubscribe();
    if (this.folderScanner) this.folderScanner.unsubscribe();
  }

  async navigateTo(section) {
    // if(section.title === 'Install App') {
    //   this.addPWAtoHomeScreen();
    //   return;
    // }
    if (section.title === 'Update App') {
      this.checkForAppUpdates();
      return;
    }
    if (section.title === 'Log Out') {
      this.singOut();
      return;
    } else {
      if (section.url.includes('http')) {
        await Browser.open({ url: section.url });
      } else {
        this.router.navigate([section.url]);
      }
    }
  }

  checkForAppUpdates(): void {

    console.log("running checkForUpdates... ")
    this.swUpdate.versionUpdates.pipe(
      tap(event => { console.log("event versionUpdates ", event) }),
      filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
    ).subscribe(async event => {
      //console.log("event checkForUpdates ", event)
      const alertDlg = await this.alertController.create({
        header: 'Update App',
        message:
          'A new version is available. Update now?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => { },
          },
          {
            text: 'Yes',
            handler: async () => {
              alertDlg.dismiss();
              this.activateUpdate();
            },
          },
        ],
      });
      await alertDlg.present();
    });

    this.swUpdate.unrecoverable.subscribe((event: UnrecoverableStateEvent) => {
      console.error('Unrecoverable state detected:', event);
    });
  }

  activateUpdate(): void {
    console.warn('activateUpdate pwa app...')
    this.swUpdate.activateUpdate().then(res => {
      if (res) {
        window.location.reload();
      }
    }).catch(error => {
      console.error('Error activating update:', error);
    });
  }

  async singOut() {
    console.log("signOut from Google ... ");
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  getServer() {
    this.dl.getServers();
  }

  async offlineLegend() {
    const toast = await this.toastController.create({
      message: 'NETWORK IS OFFLINE!',
      duration: 3000,
      cssClass: 'noNetwork-toast',
    });

    await toast.present();
  }

}


