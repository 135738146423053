import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { Command } from './command';
import { DatesFormatService } from '../dates/dates.service';
import { IdbService, IdxTables } from '../indexDB/idb.service';

import { NewJob } from 'src/app/models/interfaces/new-job';
import { PhotoInterface } from '../../models/interfaces/Photo';
import { environment } from 'src/environments/environment';
import { PhotoService } from '../photo/photo.service';
import { UpdateQueueService } from '../update-queue/update-queue.service';
import { Site } from 'src/app/models/interfaces/Site';
import { SlackBlock, SlackMessage } from 'src/app/models/SlackMessage';
import { Task } from 'src/app/models/Task';

import { Geolocation } from '@capacitor/geolocation';
import { DbService } from '../db/db.service';
import { Foreman } from 'src/app/models/interfaces/Foreman';


 
const isProduction = environment.production;
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  domain = '';
  public userSessionData;
  public sessionID: string = null;
  public apiServer: string ="NO API SERVER";
  public pdfServer: string ="NO PDF SERVER";
  public online$ = new BehaviorSubject(true);
  public online: boolean = true;
  public dynamicMenuData = new BehaviorSubject([]);
  public weekNum = new BehaviorSubject<any>(null);
  public netWorkOnline = new BehaviorSubject<boolean>(true);

  public foremanOptions: Foreman[];
  public generalForemanOptions: Foreman[];

  constructor(
    public http: HttpClient,
    private datesService: DatesFormatService,
    private idb: IdbService,
    private photos: PhotoService,
    private uQueue: UpdateQueueService,
    private localDB: DbService
  ) {
    
    this.getSession();
    this.online$.subscribe(obj => this.online = obj);
  }

  async getSession() {
    return this.sessionID = await localStorage.getItem('currentSessionID');
  }

  setSessionID(sID) {
    this.sessionID = sID;
  }

  setUserData(user) {
    this.userSessionData = user;
    localStorage.setItem('userData', JSON.stringify(user))
  }

  setSerialInDB(deviceSerial, idToken){
    let cmd = new Command();
    cmd.procedure = 'cmdRegisterDevice';

    cmd.addParameter('SerialNumber', deviceSerial);
    cmd.addParameter('JObject', '1/1/2000 10:00:00 AM');
    return this.command(cmd).pipe(
      map(retval => {
        return retval;
      })
    )
  }

  async getGeoLocation() {
    const coords = await Geolocation.getCurrentPosition()
    return coords;
  }

  async checkOnlineNetwork() {
    let url = `${this.apiServer}/api/server?sid=token`;
    return this.http.get(url).subscribe(res => {
      //console.log("checkOnlineNetwork res ", res);
      this.online$.next(true);
      this.netWorkOnline.next(true);
      this.online = true;
      //console.log('checkOnlineNetwork ', this.online)
    }, error => {
      //console.log("checkOnlineNetwork error ", error);
      this.online$.next(false);
      this.netWorkOnline.next(false);
      this.online = false;
      console.log('checkOnlineNetwork ', this.online, error)
    })
  }

  async getDashboardData() {
    if (this.online) {
      var cmd = new Command();
      cmd.procedure = "cmdGetDashboard";
      cmd.addParameter("DashboardID", 1);
      return this.command(cmd).toPromise().then(res => {
        let dash = res;
        let dashboardDataString = JSON.stringify(dash);
        localStorage.setItem('dashBoard', dashboardDataString);
        //console.log("res cmdGetDashboard => ", dash)
        return dash;
      })
    } else {
      const dashboardData = await localStorage.getItem('dashBoard');
      //console.log("res local dashboardData => ", dashboardData)
      return JSON.parse(dashboardData);
    }
  }

  async getAllForemens(): Promise<any> {
    if (this.online) {
      let cmd1 = new Command();
      cmd1.procedure = 'cmdLookupTable';
      cmd1.addParameter('LookupName', 'Foreman');
      this.foremanOptions = await this.command(cmd1).pipe(map((retval: Foreman[]) => {
        retval.forEach(f => {
          f.type = 'foreman';
        });
        return retval;
      }
      )).toPromise();

      let cmd2 = new Command();
      cmd2.procedure = 'cmdLookupTable';
      cmd2.addParameter('LookupName', 'GeneralForeman');
      this.generalForemanOptions = await this.command(cmd2).pipe(map((retval: any) => {
        retval.forEach(f => {
          f.type = 'generalForeman';
        });
        return retval;
      })).toPromise();

      let allForemans = {
        gF: this.generalForemanOptions,
        f: this.foremanOptions
      }
      localStorage.setItem('allForemans', JSON.stringify(allForemans))
      return new Promise((resolve, reject) => resolve(allForemans));
    } else {
      let allForemans = localStorage.getItem('allForemans');
      return JSON.parse(allForemans);
    }
  }

  // JOBS RELATED

  createJobRecord(job: NewJob) {
    job.workDescription = job.workType;
    let endPoint = `${this.apiServer}/api/job/add-record?sid=${this.sessionID}`;
    return this.http.post(endPoint, job);
  }

  setJobFolders(jobID) {
    let endPoint = `${this.apiServer}/api/job/${jobID}/add-folders?sid=${this.sessionID}`;
    return this.http.post(endPoint, null);
  }

  createJobEvent(jobID, event) {
    let url = `${this.apiServer}/api/job/${jobID}/Event/?eventType=${event.eventType}&sid=${this.sessionID}&startDateColumn=${event.startDateColumn}&endDateColumn=${event.endDateColumn}`;
    return this.http.post(url, event)
      .pipe(map((res: any) => res));
  }

  async getForemen(): Promise<any> {
    let cmd = new Command();
    cmd.procedure = 'cmdForemanCalendars'
    return this.command(cmd).toPromise();
  }

  getEventTypesOptions() {
    let cmd = new Command();
    cmd.procedure = 'cmdGetEventTypes';
    return this.command(cmd).toPromise().then(res => { return res })
  }


  public getAddressFromGeocoder(lat, lon) {
    let key = "AIzaSyD8QCxHhr3wkoHRgWA91jjdItTMydfuueA";
    let url: string = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${key}`;
    return this.http.get(url).pipe(
      map(res => {
        return res;
      })
    )
  }

  // ENDS JOB RELATED

  // FLEET STARTS

  getVehicleList() {
    let cmd = new Command();
    cmd.procedure = 'cmdVehicleListFiltered';
    return this.command(cmd);
  }

  // FLEET ENDS

  // TIMESHEET STARTS

  getWeekNum() {
    return this.weekNum.getValue();
  }

  // setWeekNum(weekNum) {
  //   this.weekNum.next(weekNum);
  // }
  setWeekNum(weekData) {
    
    this.weekNum.next(weekData);
  }

  async getTimeSheetCalendar(weekNum, year) {
    if (this.sessionID === null) {
      this.sessionID = await localStorage.getItem('currentSessionID');
    }
    return this.http.get(this.apiServer + '/api/TimeSheetWeek/' + year + '/' + weekNum + '?sid=' + this.sessionID);
  }

  getTimeSheetData(id) {
    return this.http.get(this.apiServer + '/api/TimeSheet/' + id + '?sid=' + this.sessionID)
      .pipe(map((obj: any) => {
        console.log('getTimeSheetData', obj);
        // debugger;
        obj.timeSheetDate = this.datesService.sqlToJsDate(obj.timeSheetDate);
        obj.shiftStart = new Date(obj.shiftStart).toLocaleString("PST");
        obj.shiftEnd = new Date(obj.shiftEnd).toLocaleString("PST");
        obj.jobs.forEach(j => {
          j.startTime = new Date(j.startTime).toLocaleString("PST");
          j.endTime = new Date(j.endTime).toLocaleString("PST");
          j.crew.forEach(c => {
            c.startTime = new Date(c.startTime).toLocaleString("PST");
            c.endTime = new Date(c.endTime).toLocaleString("PST");
          });
        });
        return obj;
      }));
  }

  getTimeSheetJobs(date, foremanID) {
    console.log('getTimeSheetJobs with data =>', date, foremanID)
    return this.http.get(this.apiServer + '/api/timesheet/jobs?foremanid=' + foremanID + '&startdate=' + date + '&sid=' + this.sessionID)
  }

  generateNewTimeSheet(tsData) {
    return this.http.post(this.apiServer + '/api/TimeSheet/?sid=' + this.sessionID, tsData).pipe(map((obj: any) => {
      console.log('generateNewTimeSheet', obj);
      obj.timeSheetDate = this.datesService.sqlToJsDate(obj.timeSheetDate);
      obj.shiftStart = this.datesService.sqlToJsDate(obj.shiftStart);
      obj.shiftEnd = this.datesService.sqlToJsDate(obj.shiftEnd);
      obj.jobs.forEach(j => {
        j.startTime = this.datesService.sqlToJsDate(j.startTime);
        j.endTime = this.datesService.sqlToJsDate(j.endTime);
      });
      return obj;
    }));
  }

  timeSheetValidation(timeSheetID) {
    let url = this.apiServer + '/api/timesheet/' + timeSheetID + '/validation?sid=' + this.sessionID;
    return this.http.get(url);
  }

  saveTimeSheet(timeSheet) {
    return this.http.put(this.apiServer + '/api/timesheet/' + timeSheet.timeSheetID + '?sid=' + this.sessionID, timeSheet);
  }

  saveDefaultsInTimeSheet(tsDefaults) {
    return this.http.post(this.apiServer + '/api/user/settings/timesheet-defaults/?sid=' + this.sessionID, tsDefaults);
  }


  // TIMESHEET ENDS

  // JOBS

  /** load the job from the server if online, but offer forced refresh as choice */
  async jobLoader(jobID: number, forceRefresh = false) {
    console.log("jobLoader force=", forceRefresh);

    let job;

    if (!forceRefresh) {
      job = await this.idb.select('jobFolders', jobID);
    } else {
      console.log('force refresh bypasses synced check')
    }

    if (this.online) {
      let url = `${this.apiServer}/api/mobile/jobloader/${jobID}?sid=${this.sessionID}`;
      job = await this.http.get(url).toPromise();
    //console.log("JOB FROM SERVER MAP => ", job);

      const existingJob = await this.idb.select('jobFolders', jobID);
      if (existingJob) {
        await this.idb.update('jobFolders', jobID, job);
      } else {
        await this.idb.insert('jobFolders', job);
      }

    return job;

    } else {
      console.log('offline, checking cache');
      if (!job) {
        //just in case this was a forceRefrehs, but we weren't online
        job = await this.idb.select('jobFolders', jobID);
      }

      if (job) {
        return new Promise((resolve, reject) => { resolve(job) });
      }
      //if not online and not in cache, return a shell of the job and hope for the best.  :)
      console.log('building shell offline')
      job = await this.jobShell(jobID);
      return new Promise((resolve, reject) => { resolve(job) });
    }
  }
  
  async jobBulkLoader(jobsBulkArray) {
    let jobsBulkData;
    //let job;

    if (this.online) {
       let url = `${this.apiServer}/api/mobile/jobloader/bulk?sid=${this.sessionID}`;
       jobsBulkData = await this.http.post(url, jobsBulkArray).toPromise();
      console.log("JOBS FROM BULK LOADER => ", jobsBulkData);

      jobsBulkData.forEach(async job => {
        let jobID = job.jobID
        const existingJob = await this.idb.select('jobFolders', jobID);
        if (existingJob) {
          await this.idb.update('jobFolders', jobID, job);
        } else {
          await this.idb.insert('jobFolders', job);
        }
  
        // for (const ref of job.imageRefs) {
        //   this.getPhotoByReferenceCode(ref);
        // }
  
        return job;
  
      
      });
    } else {
      return

    }
  }

  sortJobNotesByCreatedDate(jobNotes) {
    return jobNotes.sort((a, b) => {
      const dateA: any = new Date(a.createdDate);
      const dateB: any = new Date(b.createdDate);
      return dateB - dateA; // Sort in descending order
    });
  }

  async jobCommentsLoader(jobID: number, forceRefresh = false): Promise<any[]> {
    let jobRecordWithComments;
  
    // 1. Load from IndexedDB if not forcing refresh
    if (!forceRefresh) {
      console.log('Loading jobComments from IndexedDB... jobId', jobID);
      jobRecordWithComments = await this.idb.select(IdxTables.JobCommentsTable, jobID);
      console.log('CACHE: jobComments => ', jobRecordWithComments);
    } else {
      console.log('Force refresh - bypass local cache');
    }

    if(!jobRecordWithComments) {
      jobRecordWithComments = {jobID: jobID, comments: []};
    }

    // 2. If online, load from server
    if (this.online) {
      //console.log('>>> Loading jobComments from server...');
      const cmd = new Command();
      cmd.procedure = 'cmdJobNotes';
      cmd.addParameter('JobID', jobID);
      cmd.addParameter('NoteType', '');
  
      // Convert the Observable returned by this.command(cmd) into a Promise
      const retval = await this.command(cmd).toPromise();
      jobRecordWithComments.comments = this.sortJobNotesByCreatedDate(retval);
      //console.log('CMD: jobRecordWithComments => ', jobRecordWithComments);
  
      // 3. Store/Update in IndexedDB
      console.log('Saving jobComments to IndexedDB...');
      const existingJob = await this.idb.select(IdxTables.JobCommentsTable, jobID);
      //console.log('existingJob => ', existingJob);
  
      if (existingJob) {
        await this.idb.update(IdxTables.JobCommentsTable, jobID, jobRecordWithComments);
      } else {
        await this.idb.insert(IdxTables.JobCommentsTable, jobRecordWithComments);
      }
    }
  
    // 4. Return the final comments
    return jobRecordWithComments.comments ?? [];
  }

  async jobCommentTypeLoader(forceRefresh = false) {
    let jobCommentTypes;
  
    // 1) If we're not forcing a refresh, try loading from localStorage
    if (!forceRefresh) {
      console.log('Loading jobCommentTypes from localStorage...');
      //TODO: Move to index db
      const raw = localStorage.getItem(IdxTables.JobCommentTypesTable);
      if (raw) {
        jobCommentTypes = JSON.parse(raw);
        console.log('CACHE: jobCommentTypes => ', jobCommentTypes);
      }
    } else {
      console.log('Force refresh bypasses synced check');
    }
  
    // 2) If we are online, load from the server instead
    if (this.online) {
      console.log('Loading jobCommentTypes from server...');
      const cmd = new Command();
      cmd.procedure = 'cmdOptionList';
      cmd.addParameter('OptionName', 'JobNoteType');
  
      // If `this.command(cmd)` returns a Promise, do:
      const retval = await this.command(cmd).toPromise();
      console.log('CMD: jobCommentTypes => ', retval);
  
      // Save to localStorage for next time
      localStorage.setItem(IdxTables.JobCommentTypesTable, JSON.stringify(retval));
  
      // Use the newly fetched data
      jobCommentTypes = retval;
    }
  
    console.log('Returning jobCommentTypes => ', jobCommentTypes);
    return jobCommentTypes;
  }

  async jobShell(jobID: number) {

    var row = await this.idb.select('jobFolders', jobID);
    if (row.crewFolder && !row.crewFolderID) row.crewFolderID = row.crewFolder;

    //build a shell of the locations list
    if (!row.locations) row.locations = '1';
    if (row.locations === '') row.locations = '1';
    let locs = eval('[' + row.locations + ']');
    row.locations = [];
    locs.forEach(l => {
      row.locations.push({ siteID: 0, locationNumber: l, address1: row.address1, city: row.city });
    });
    return row;
  }

  getJobsFromIdb() {
    return new Promise((resolve, reject) => {
      var Jobs = [];
      var transaction = this.idb.db.transaction(["jobFolders"]);
      transaction.oncomplete = () => resolve(Jobs);
      transaction.onerror = () => reject(null);
      var request = transaction.objectStore('jobFolders').getAll();
      request.onsuccess = (event) => Jobs = request.result
    });
  }

  deleteJobsRange(table, starts, ends) {
    return this.idb.deleteRange(table, starts, ends);
  }

  async addNewLoc(jobId, locationNumber, address, lat, lon, jobNumber): Promise<any> {
    let referencCode = ((new Date()).getTime()).toString();
    let cmd = new Command();
    cmd.procedure = 'cmdAddSiteByReference';
    cmd.addParameter('JobID', jobId);
    cmd.addParameter('LocationNumber', locationNumber);
    cmd.addParameter('Address', address);
    cmd.addParameter('Lat', lat);
    cmd.addParameter('Lon', lon);
    cmd.addParameter('ReferenceCode', referencCode);
    let obj = {
      url: this.apiServer + '/api/cmd?sid=' + this.sessionID,
      method: 'POST',
      payload: cmd
    };

    let site: Site = new Site();
    site.siteID = 0, site.status = 'Active',
      site.jobID = jobId, site.locationNumber = locationNumber, site.address1 = address, site.latitude = lat,
      site.longitude = lon, site.siteID = parseInt(referencCode), site.jobNumber = jobNumber, site.reports = [], site.status = 'Active';


    this.uQueue.addToQueue(obj);
    var jp = await this.idb.select('jobFolders', jobId);
    if (jp) {
      jp.locations.push(site);
    }
    jp = await this.idb.update('jobFolders', jobId, jp);

    return of(site).toPromise();
  }

  loadReportTemplates(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      //if(!this.online) return
      try {
        console.log('getting report templates...')
        let url = `${this.apiServer}/api/Mobile/ReportTemplates?sid=${this.sessionID}`
        let reports = await this.http.get<any[]>(url).toPromise()
        if (reports.length > 0) {
          await this.idb.deleteRange('reportTemplates', 1, 1000000); //clear everything
          reports.forEach(async (r) => {
            try {
              await this.idb.insert('reportTemplates', r);
            } catch (e) {
              console.log(r.reportTemplateName, e);
            }
          });
          console.log('reports found => ', reports)
          resolve(reports)
        } else {
          console.log('no reports found!')
          resolve(null);
        }
      } catch (e) {
        reject('error trying to load report');
      }
    });
  }


  getReportData(reportTemplateName, referenceCode = 0) {
    return new Promise(async (resolve, reject) => {
      try {
        let report = await this.idb.selectByIndex('reportTemplates', 'reportTemplateName', reportTemplateName)

        if (report && reportTemplateName != 'VehicleConditionReport') {
          console.log('found report for reportTemplate ' + reportTemplateName + ' in local db')
          resolve(report);
        } else {

          if (!this.online) resolve(null);
          let url = `${this.apiServer}/api/Mobile/ReportTemplates/${reportTemplateName}?sid=${this.sessionID}`
          let report = await this.http.get<any>(url).toPromise()
          if (report) {
            if (reportTemplateName != 'VehicleConditionReport') await this.idb.insert('reportTemplates', report);
            resolve(report);
          } else {
            console.log('no report found for reportTemplate ' + reportTemplateName + '!')
            resolve(null);
          }
        }
      } catch (e) {
        reject('error trying to load report');
      }
    })
  }


  getReportUrl(report, job, refCode) {
    let server = environment.pdfServer;
    let url = server + '/api/ContractorBillingForm?id=' + job.jobID + '&referenceCode=' + refCode + '&template=' + report.reportName + '&sid=' + this.sessionID;
    return url;
  }


  saveGenericReport(jobID, templateName, locationNumber, referenceCode, data) {
    var userString = localStorage.getItem('user');
    var user = JSON.parse(userString);
    data.createdDate = new Date();
    data.createdByUser = user.email;
    let obj = {
      url: `${this.apiServer}/api/jobs/${jobID}/locations/${locationNumber}/reports/${templateName}/${referenceCode}?sid=${this.sessionID}`,
      method: 'POST',
      payload: data //{reportID: note.reportID, noteText: note.noteText, status: note.status}
    };
    if (this.online) {
      console.log('saving report', obj)
      return this.http.post(obj.url, data).toPromise();
    } else {
      this.uQueue.addToQueue(obj);
      return new Promise((resolve, reject) => { resolve({ "previewUrl": "offline", "msg": "Added to Update Queue" }) });
    }
  }

  saveReportInJob(job) {
    let jobObj = {
      url: `${this.apiServer}/api/mobile/jobloader/${job.jobID}?sid=${this.sessionID}`,
      method: 'POST',
      payload: job
    }
    this.uQueue.addToQueue(jobObj)
  }

  saveSignature(signature: any) {
    let obj: Task = {
      url: this.apiServer + '/api/Image?sid=' + this.sessionID,
      method: 'POST',
      payload: signature
    };
    this.uQueue.addToQueue(obj);
    this.uQueue.runQueue().then();
    return of(signature);
  }

  async loadQuestions() {
    // console.log('getCurrentNetworkStatus', this.networkService.getCurrentNetworkStatus());
    // if (this.networkService.getCurrentNetworkStatus() == 0) {
    //   console.log('offline.  cancelling loadQuestions')
    //   return null;
    // }
    let url = this.apiServer + '/api/QuestionLoader?sid=' + this.sessionID;
    return this.http.get(url).pipe(
      map((data: any) => {
        localStorage.setItem('tblQuestion', data);
        return data;
      })
    ).toPromise();
  }

  public getQuestionTableByID(questionList): Observable<any> {
    return from(localStorage.getItem('tblQuestion')).pipe(
      map((res: any) => {
        res.forEach(item => {
          item.questions = item.questions.filter(q => questionList.includes(q.questionID));
        });
        return res;
      })
    )
  }

  getReportTemplateTable(refresh: boolean = false): Observable<any> {

    if (!refresh) {
      let data = this.localDB.reportTemplateTable;
      if (data) {
        // console.log("loaded report templates from localDB", data.length);
        if (data.length == 0) {
          // console.log("no local report templates found in localDB.  loading from storage");
          return from(localStorage.getItem('tblReportTemplate')).pipe(
            map((retval: any) => {
              // console.log("populated localDB report templates with", retval.length);
              this.localDB.reportTemplateTable = retval;
            })
          )
        } else {
          return of(data);
        }
      } else {
        return from(localStorage.getItem('tblReportTemplate')).pipe(
          map((retval: any) => {
            // console.log("populated localDB report templates with", retval.length);
            this.localDB.reportTemplateTable = retval;
          })
        )
      }
    } else {
      let cmd = new Command();
      cmd.procedure = "cmdGetReportTemplates";
      return this.command(cmd).pipe(
        map(retval => {
          this.localDB.reportTemplateTable = retval;
          localStorage.setItem('tblReportTemplate', retval);
          return retval;
        })
      )
    }
  }


  //if the job is in indexedb, then update it, otherwise add it.
  async updateJobFolder(job) {
    let jobId = job.jobID;
    var key = await this.idb.keyExists('jobFolders', jobId);
    if (key) {
      await this.idb.update('jobFolders', jobId, job);
    } else {
      await this.idb.insert('jobFolders', job);
    }
  }

  getTrackedAssetsByDeviceSerial(deviceSerial){
    let cmd = new Command();
    cmd.procedure = 'cmdGetTrackedAssetsByDeviceSerial';

    cmd.addParameter('DeviceSerial', deviceSerial);
    cmd.addParameter('LastScanDate', '1/1/2000 10:00:00 AM');
    return this.command(cmd).pipe(
      map(retval => {
        return retval;
      })
    )
  }

  async logIt(title, note, obj = null) {

    let mydate = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()

    // TODO!!!
    //let myuser = this.userEmail;
    let myuser = 'qa.sitereview';

    title = '*' + title + '* : ' + myuser + ' - ' + mydate;
    var msg = new SlackMessage();
    msg.blocks = [
      {
        "type": "divider"
      },
      {
        type: "section", text: {
          type: "mrkdwn",
          text: title
        }
      },
      {
        type: "section", text: {
          type: "mrkdwn",
          text: note
        }
      }
    ]
    if (obj) {
      let blk = new SlackBlock(JSON.stringify(obj));
      msg.blocks.push({ "type": "divider" })
      msg.blocks.push(blk)
      msg.blocks.push({ "type": "divider" })
    }
    this.slacker(msg);
  }

  async slacker(msg: SlackMessage) {
    let obj = {
      url: this.apiServer + '/api/logger/slack',
      method: 'POST',
      payload: msg
    };
    this.uQueue.addToQueue(obj);
    this.uQueue.runQueue();
  }



  // ENDS JOBS


  // PHOTOS

  uploadPhoto(photo: any): Promise<boolean> {
    photo.sessionId = this.sessionID;
    let obj = {
      url: this.apiServer + '/api/Image?sid=' + this.sessionID,
      method: 'POST',
      payload: photo
    };
    this.uQueue.addToQueue(obj);
    return this.photos.savePhoto(photo);
  }

  async getPhotoByReferenceCode(referenceCode: any, width: number = 0): Promise<PhotoInterface> {
    return new Promise<PhotoInterface>(async (resolve, reject) => {
      let format = 1  //in the api as by reference code
      let p = await this.photos.getPhoto(referenceCode);
      if (p) {
        resolve(p);
      } else {
        if (!this.online) {
          resolve(null);
        }
        console.log('loading image ' + referenceCode + ' from server')
        let url = `${this.apiServer}/api/Image/${referenceCode}?format=${format}&width=${width}&sid=${this.sessionID}`;
        let photo: PhotoInterface = await this.http.get<PhotoInterface>(url).toPromise()
        if (photo) {
          await this.photos.savePhoto(photo);
        }
        resolve(photo);
      }
    });
  }

  /** send string referenceCode to delete from indexedb and server */
  async deletePhoto(referenceCode: string = '', jobID: number = 0, locationNumber: number = 0, reportCode: string = '') {
    await this.photos.deletePhoto(referenceCode);
    var url = `${this.apiServer}/api/image?sid=${this.sessionID}&referenceCode=${referenceCode}&JobID=${jobID}&locationNumber=${locationNumber}&reportCode=${reportCode}`;
    let obj = {
      url: url,
      method: 'DELETE',
      payload: { referenceCode: referenceCode }
    };
    this.uQueue.addToQueue(obj);
    this.uQueue.runQueue();
  }

  async setCoverPhoto(photo) {
    console.log('photo cover')
    let loc = photo.locationNumber ? photo.locationNumber : 0;

    let cmd = new Command();
    cmd.procedure = 'cmdSetCoverPhotoByRef';

    cmd.addParameter('JobID', photo.jobID);
    cmd.addParameter('locationNumber', photo.locationNumber);
    cmd.addParameter('ReferenceCode', parseInt(photo.referenceCode));
    let obj = {
      url: this.apiServer + '/api/cmd?sid=' + this.sessionID,
      method: 'POST',
      payload: cmd
    };
    await this.uQueue.addToQueue(obj);
    this.uQueue.runQueue().then(); //just kick it off and move on.
    let job = await this.jobLoader(photo.jobID, true)
    if (loc > 0) {
      let locations = job.locations.filter(l => l.locationNumber == loc);
      if (locations.length > 0) {
        locations[0].imageRefs = locations[0].imageRefs.filter(img => img.referenceCode != photo.referenceCode);
      }
    } else {
      job.imageRefs = job.imageRefs.filter(img => img.referenceCode != photo.referenceCode);
    }
    await this.updateJobFolder(job);
    return of(job.imageRefs).toPromise();
  }

  getDrivePhotosUrl(referenceCodes) {
    var url = `${this.apiServer}/api/images/byref?${referenceCodes}&sid=${this.sessionID}`;
    return this.http.get(url);
  }


  // GENERALS

  setMenuDashboardData(data) {
    this.dynamicMenuData.next(data);
    //console.log('setMenuDashboardData => ', this.dynamicMenuData.getValue())
  }

  command(commandObject: any): Observable<any> {
    let url: string = this.apiServer + '/api/cmd?sid=' + this.sessionID + '&proc=' + commandObject.procedure;
    return this.http.post(url, commandObject)
      .pipe(
        map((response: Response) => <any>response)
      );
  }

  processBase64String(base64String) {
    if (!base64String) {
      return base64String;
    }

    const jpegPrefix = 'data:image/jpeg;base64,';
    const pngPrefix = 'data:image/png;base64,';

    if (base64String.indexOf(jpegPrefix) !== 0 && base64String.indexOf(pngPrefix) !== 0) {
      return base64String = jpegPrefix + base64String;
    } else {
      return base64String;
    }
    //console.log("RETURNING processBase64String => ", base64String)
  }

  // command(commandObject: any): Promise<any> {
  //   let url: string = this.apiServer + '/api/cmd?sid=' + this.sessionID + '&proc=' + commandObject.procedure;
  //   return this.http.post(url, commandObject)
  //   .toPromise()
  //   .then((response: any) => response)
  //   .catch((error: any) => {
  //     console.error('Error:', error);
  //     throw error; // Rethrow the error to propagate it to the next catch block
  //   })
  // }

  getServers() {

    this.domain = window.location.host;
    this.apiServer = environment.apiServer;
    this.pdfServer = environment.pdfServer;

    //  NONE OF THIS SHIT WORKED RIGHT.
    // if (this.domain == 'field.pinnaclepowerservices.org' || isProduction) {
    //   console.log('####### PRODUCTION API SERVER #########')
    //   console.log('API SERVER:' + this.apiServer);
    //   this.apiServer = PROD_API_SERVER;
    //   this.pdfServer = PROD_PDF_SERVER;
    // } else {
    //   console.log('API SERVER:' + this.apiServer);
    //   this.apiServer = DEV_API_SERVER;
    //   this.pdfServer = DEV_PDF_SERVER;

    // }
    //console.log('API SERVER:' + this.apiServer);
    // alert('wtf');
  }

}


