import { Injectable } from '@angular/core';
import { ta } from 'date-fns/locale';

export const IdxTables = {
  JobCommentsTable: 'jobComments',
  JobCommentTypesTable: 'jobCommentTypes',
}

@Injectable({
  providedIn: 'root'
})
export class IdbService {
  version = 45;
  databaseName = 'pinnacle';
  db;
  
  // good resource: https://www.w3docs.com/learn-javascript/javascript-indexeddb.html
  constructor() {
  }

  async openDB(version: number = 0) {

    console.log('>>>> opening request to indexedDB')
    var request;
    if (version == 0) {
      request = indexedDB.open(this.databaseName);
    } else {
      request = indexedDB.open(this.databaseName, version);
    }

    return new Promise(async (resolve, reject) => {
      request.onsuccess = () => {
        //console.log("successfully opened database")
        this.db = request.result;
        resolve(request);
      }
      request.onerror = () => { console.log('error opening db', request); reject(request) }
    })
  }

  async initializeDB() {
    return new Promise((resolve, reject) => {
      console.log('>>>>>>initializing IndexedDB v' + this.version);

      // 1. If it is fine, send it back
      if (this.db) {
        if (this.db.sta) resolve(this.db)
      }

      console.log('initializing IndexedDB v' + this.version)
      let name = this.databaseName;
      let version = this.version;
      console.log('PROGRESS: opening request to indexedDB')

      var request = indexedDB.open(name, version);
      request.onsuccess = () => {
        console.log("PROGRESS: successfully opened database")
        this.db = request.result;
        resolve(request);
      }
      request.onblocked = () => {
        console.log('error opening db - blocked', request);
        reject(request);
      }
      request.onerror = () => {
        console.log('error opening db', request);
        reject(request)
      }
      request.onblocked = () => {
        console.log('blocked', request); reject(request)
      }
      request.onupgradeneeded = (event: any) => {
        console.log("UPGRADING db")
     
        this.db = request.result;
        var txn = event.target.transaction;

        /* in the event there's a db with no stores, you sometimes get an error */
        // var hasJobs = false;
        // try { hasJobs = this.db.objectStoreNames.contains('jobs') } catch (e) { hasJobs = false; console.log('failed to read Object Store Jobs') }
        // if (!hasJobs) {
        //   console.log('adding jobs store');
        //   let objectStore: IDBObjectStore = this.db.createObjectStore('jobs', { keyPath: 'jobID' });
        //   objectStore.createIndex("jobNumber", "jobNumber");
        //   objectStore.createIndex('updatedDate', 'updatedDate');
        // }

        var hasJobFolders = false;
        try { hasJobFolders = this.db.objectStoreNames.contains('jobFolders') } catch (e) { hasJobFolders = false; console.log('failed to read Object Store JobFolders') }
        if (!hasJobFolders) {
          console.log('adding jobFolders store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('jobFolders', { keyPath: 'jobID' });
          objectStore.createIndex("jobNumber", "jobNumber");
          objectStore.createIndex('updatedDate', 'updatedDate');
        }

        //console.log('>>>#### adding jobFolders store');
        var hasJobComments = false;
        try { 
          hasJobComments = this.db.objectStoreNames.contains(IdxTables.JobCommentsTable) 
        } catch (e) { 
          hasJobComments = false; 
          console.log('failed to read Object Store JobFolders') 
        }
        if (!hasJobComments) {
          console.log('adding jobComments store');
          let objectStore: IDBObjectStore = this.db.createObjectStore(IdxTables.JobCommentsTable, { keyPath: 'jobID' });
          // objectStore.createIndex("jobId", "jobId");
          // objectStore.createIndex('updatedDate', 'updatedDate');
        }

        var hasTrackedFolders = false;
        try { hasTrackedFolders = this.db.objectStoreNames.contains('trackedFolders') } catch (e) { hasTrackedFolders = false; console.log('failed to read Object Store trackedFolders') }
        if (!hasTrackedFolders) {
          console.log('adding trackedFolders store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('trackedFolders', { keyPath: 'folderID' });
          objectStore.createIndex("jobID", "jobID");
          objectStore.createIndex("jobNumber", "jobNumber");
          objectStore.createIndex('updatedDate', 'updatedDate');
        }


        var hasPhotos = false;
        try { hasPhotos = this.db.objectStoreNames.contains('photos') } catch (e) { hasPhotos = false; console.log('failed to read Object Store photos') }
        if (!hasPhotos) {
          console.log('adding photos store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('photos', { keyPath: 'referenceCode' });
          objectStore.createIndex("jobID", "jobID");
          objectStore.createIndex('locationNumber', 'locationNumber');
        }


        var hasUpdateQueue = false;
        try { hasUpdateQueue = this.db.objectStoreNames.contains('updateQueue') } catch (e) { hasUpdateQueue = false; console.log('failed to read Object Store updateQueue') }
        if (!hasUpdateQueue) {
          console.log('adding updateQueue store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('updateQueue', { keyPath: 'queueID' });
          objectStore.createIndex("counterTries", "counterTries");
          objectStore.createIndex('updatedDate', 'updatedDate');
        }


        var hasReportTemplates = false;
        try { hasReportTemplates = this.db.objectStoreNames.contains('reportTemplates') } catch (e) { hasReportTemplates = false; console.log('failed to read Object Store reportTemplates') }
        if (!hasReportTemplates) {
          console.log('adding reportTemplates store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('reportTemplates', { keyPath: 'reportTemplateID' });
          objectStore.createIndex("reportTemplateName", "reportTemplateName");
          objectStore.createIndex('updatedDate', 'updatedDate');
        }

        var hasReports = false;
        try { hasReports = this.db.objectStoreNames.contains('reports') } catch (e) { hasReports = false; console.log('failed to read Object Store reports') }
        if (!hasReports) {
          console.log('adding reports store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('reports', { keyPath: 'referenceCode' });
          objectStore.createIndex("reportID", "reportID");
          objectStore.createIndex('jobID', 'jobID');
        }

        var hasFiles = false;
        try { hasFiles = this.db.objectStoreNames.contains('files') } catch (e) { hasFiles = false; console.log('failed to read Object Store files') }
        if (!hasFiles) {
          console.log('adding files store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('files', { keyPath: 'id' });
          objectStore.createIndex("code", "code");
          objectStore.createIndex("name", "name"); 
          objectStore.createIndex("path", "path");    
          objectStore.createIndex("modifiedDate", "modifiedDate");      
          objectStore.createIndex('jobID', 'jobID');
        }


        this.db = request.result;
        txn.oncomplete = (event) => {
          resolve(request);
        }

      }
    });
  }

  async deleteDatabase() {
    try { this.db.close() } catch (e) { console.log('error closing db on delete') }
    var request = indexedDB.deleteDatabase(this.databaseName);
    return new Promise(async (resolve, reject) => {
      request.onsuccess = () => {
        console.log("successfully deleted the database")
        this.db = request.result;
        resolve(request.result);
      }
      request.onerror = () => {
        console.log("shit. it didn't delete", request.error)
        reject(request.error);
      }
    })
  }

  simpleDelete(table, key) {
    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.count(key);

    request.onsuccess = () => {
      if (request.result === 0) {
        return new Promise((resolve, reject) => {
          resolve('id not found')
        })
      } else {
        let deleteRequest = objectStore.delete(key);
        return new Promise((resolve, reject) => {
          deleteRequest.onsuccess = (retval) => {
            console.log(`delete successful for ${key} in ${table}`)
            resolve(retval);
          }
          deleteRequest.onerror = (retval) => {
            console.log(`delete failed for ${key} in ${table}`)
            reject(retval);
          }
        })
      }
    }
    request.onerror = () => { return new Promise((resolve, reject) => { resolve('id not found') }) }

  }


  async delete(table: string, key: any): Promise<any> {
    //this.db = await this.initializeDB();

    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.get(key);
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => {
        let record = retval.target.result;
        console.log('got a record', record)
        if (record) {
          let deleteRequest = objectStore.delete(key);
          deleteRequest.onsuccess = (retval) => {
            console.log('delete successful', retval)
            resolve(retval);
          }
        } else {

          request.onerror = (retval) => {
            let record = retval.target.result;
            if (record) {
              let deleteRequest = objectStore.delete(key);
              deleteRequest.onerror = (retval) => {
                console.log('delete failed', retval)
                reject(retval);
              }
            }
          }
        }
      }
    });
  }

  clearTable(table: string){
    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var objectStoreRequest = objectStore.clear();

    objectStoreRequest.onsuccess = function(event) {
        console.log('Erase of database completed.');
    };
  }

  async get(table: string, key: any): Promise<any> {
    //console.log('get idb with table ', table + ' and key ', key)
    try {
        const objectStore = this.db.transaction(table, 'readonly').objectStore(table);
        //console.log("objectStore => ", objectStore, key.toString())
        const request = objectStore.get(key);

        return new Promise((resolve, reject) => {
            request.onsuccess = (event) => {
                const data = event.target.result;
                //console.log("OK GETTING DATA", data);
                resolve(data);
            };

            request.onerror = (event) => {
                console.error("ERROR GETTING DATA", event.target.error);
                reject(event.target.error);
            };
        });
    } catch (error) {
        console.error("ERROR GETTING DATA", error);
        throw error;
    }
}


  async getKeys(table: string): Promise<number[]> {
    return new Promise((resolve, reject) => {
      var retval = [];
      var transaction = this.db.transaction([table]);
      transaction.oncomplete = () => resolve(retval);
      transaction.onerror = () => reject(null);
      var request = transaction.objectStore(table).getAllKeys();
      request.onsuccess = (event) => retval = request.result
    });
  }

  async keyExists(table: string, id: any): Promise<boolean> {
    //debugger
    if(!table || !id || table === '' || id === '' || table === null || id === null || table === 'null' || id === 'null') return false
    return new Promise((resolve, reject) => {
      var retval = false;
      var transaction = this.db.transaction([table]);
      transaction.oncomplete = () => {
        console.log('transaction success getting key ' + id + ' from object store ' + table)
        resolve(retval);
      }
      transaction.onerror = () => {
        console.log('error getting the key!')
        reject(null);
      }
      var request = transaction.objectStore(table).getKey(id);
      request.onsuccess = (event) => {
        console.log('success', event.target.result)
        retval = event.target.result;
      }
      request.onerror = (error) => {
        console.log('error in key exists', error)
      }
    });
  }
  async updateStatus(direction: string = 'first'): Promise<Date[]> {
    direction = (direction === 'first') ? 'prev' : 'next'
    let table = 'jobFolders';
    var transaction = this.db.transaction(table, "read");

    var objectStore = transaction.objectStore(table);
    var request = objectStore.index('updatedDate').openCursor(null, direction); // or prev 
    let res = [];
    return new Promise((resolve, reject) => {
      request.onsuccess = (event) => {

        var cursor = event.target.result;
        if (cursor) {
          if (res.length < 1) {
            res.push(cursor.value.updatedDate);
            cursor.continue();
          } else {
            cursor.advance(1000000000);
          }
        } else {

          resolve(res);
        }

      }
    });

  }
  async deleteByUpdateDate(table: string, beginDate: Date, endDate: Date, messageBoxObject = null) {
    // this.db = await this.initializeDB();
    let beginTime = beginDate.getTime();
    let endTime = endDate.getTime();
    var transaction = this.db.transaction(table, "readwrite");
    var objectStore = transaction.objectStore(table);
    var request = objectStore.openCursor();
    request.onsuccess = function (event) {
      var cursor = event.target.result;
      if (cursor) {
        // cursor.value contains the current record being iterated through
        // this is where you'd do something with the result
        let ud = new Date(cursor.value.updatedDate).getTime();
        if (ud > beginTime && ud < endTime) {
          if (messageBoxObject) {
            messageBoxObject.comment = 'clearing job ' + cursor.value.jobID
          }
          console.log('deleting local copy of job ', cursor.value.jobID);
          cursor.delete();
        }

        cursor.continue();
      } else {
        // no more results
      }
    };
  }
  async deleteRange(table: string, start: number, end: number): Promise<any> {
    if (!this.db) this.db = await this.initializeDB();
    var keyRangeValue = IDBKeyRange.bound(start, end);

    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.delete(keyRangeValue);
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => {
        console.log('deleted range', retval)
        resolve(retval);

      }
    });
  }

  async select(table: string, key: any): Promise<any> {
    //   this.db = await this.initializeDB();
    return new Promise((resolve, reject) => {
      var objectStore = this.db.transaction(table).objectStore(table);
      var request = objectStore.get(key);
      request.onsuccess = (retval) => {
        let record = retval.target.result;
        resolve(record);
      }
      request.onerror = (e) => {
        console.log('Error doing select from IDB', e.target.error);
        reject(e.target.error);
      }
    });
  }


  async selectByIndex(table: string, index: string, key: any): Promise<any> {
    //   this.db = await this.initializeDB();
    return new Promise((resolve, reject) => {
      var objectStore = this.db.transaction(table).objectStore(table).index(index);
      var request = objectStore.get(key);
      request.onsuccess = (retval) => {
        let record = retval.target.result;
        resolve(record);
      }
      request.onerror = (e) => {
        console.log('Error doing select from IDB', e.target.error);
        reject(e.target.error);
      }
    });
  }

  async selectRangeByIndexID(table:string,index:string, id:number): Promise<any>{


    return new Promise((resolve, reject) => {
      const keyRangeValue = IDBKeyRange.only(id);
      var transaction = this.db.transaction([table], "readonly");
      let records = [];
      var objectStore = this.db.transaction(table).objectStore(table).index(index);
      var request = objectStore.openCursor(keyRangeValue);
      request.onsuccess = (retval) => {
        let cursor = retval.target.result;
       
        if (cursor) {
          console.log('Found Row.', cursor.value);
          records.push(cursor.value)
          cursor.continue();
        } else {
          console.log('Entries all displayed.');
        }
      }
      transaction.oncomplete = function(event) {
        console.log("completed")
        resolve(records);
    };

      request.onerror = (e) => {
        console.log('Error doing select from IDB', e.target.error);
        reject(e.target.error);
      }
    });

  }
  async list(table: string): Promise<any> {
    //  this.db = await this.initializeDB();
    console.log('listing table')

    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.getAll();
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => {
        let record = retval.target.result;
        resolve(record);
      }
    });
  }

  async update(table: string, key: any, data: any): Promise<any> {
    try {
        var tx = await this.db.transaction(table, 'readwrite');
        var objectStore = tx.objectStore(table);
        var request = objectStore.get(key);

        return new Promise((resolve, reject) => {
            request.onsuccess = (event) => {
                let record = event.target.result;
                if (!record) {
                    reject("Record not found");
                    return;
                }

                // Update the record with the new data
                let updateRequest = objectStore.put(data);
                updateRequest.onsuccess = () => {
                    resolve(data);
                };
                updateRequest.onerror = (event) => {
                    console.error('Error updating record:', event.target.error);
                    reject(event.target.error);
                };
            };

            request.onerror = (event) => {
                console.error('Error fetching record:', event.target.error);
                reject(event.target.error);
            };
        });
    } catch (error) {
        console.error('Error opening transaction:', error);
        throw error;
    }
}


  // async update(table: string, key: any, data: any): Promise<any> {
  //   //    this.db = await this.initializeDB();
  //   //this.db = await this.initializeDB().then(obj => { console.log(obj); }, err => console.log(err));

  //   var tx = await this.db.transaction(table, 'readwrite');
  //   var objectStore = tx.objectStore(table);
  //   var request = objectStore.get(key);
  //   return new Promise(async (resolve, reject) => {
  //     request.onsuccess = async (retval) => {
  //       let record = retval.target.result;
  //       if (!record) {
  //         let insertedRec = await this.insert(table, data);
  //         resolve(insertedRec);
  //       }

  //       let updateRequest = objectStore.put(data);
  //       updateRequest.onsuccess = () => { resolve(record); }
  //       updateRequest.onerror = (e) => {
  //         console.log('Error doing update into IDB', e.target.error);
  //         reject(e.target.error);
  //       }
  //     }
  //   });
  // }

  // async insert(table: string, data: any): Promise<any> {
  //   // this.db = await this.initializeDB();

  //   var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
  //   var request
  //   try{
  //     console.log('INSERTING RECORD',data);
  //     request = objectStore.add(data);
  //   }catch(e){
  //     console.error('ERROR INSERTING RECORD',data);
  //   }
  //   console.log('RECORD INSERTED')
  //   return new Promise((resolve, reject) => {
  //     request.onsuccess = (retval) => { 
  //       resolve(retval.result);
  //      }
  //     request.onerror = (e) => { 
  //       console.error("ERROR INSERTING",data)
  //       reject(e.target.error);
  //      }

  //   });
  // }

  async insert(table: string, data: any): Promise<any> {
    try {
        const objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
       // console.log('INSERTING RECORD', data);

        // Ensure that the data contains the key path (folderID)
        let keyPath = data?.folderID;
        if (keyPath === undefined){
          keyPath = data?.rootFolderID;
          data.folderID = keyPath;
        }
        //console.log('Key Path Value:', data.folderID);

        //if(data.folderID === undefined) return;

        // Use folderID as the key for insertion
        const request = objectStore.put(data);

        return new Promise((resolve, reject) => {
            request.onsuccess = (event) => {
                console.log('RECORD INSERTED');
                resolve(event.target.result);
            };

            request.onerror = (event) => {
                console.error("ERROR INSERTING", data, event.target.error);
                reject(event.target.error);
            };
        });
    } catch (error) {
        console.error("ERROR INSERTING RECORD", data, error);
        throw error;
    }
}


async insertBulk(table: string, rows, callback) {
  let startTime = new Date();
  const tx = this.db.transaction(table, 'readwrite');

  rows.forEach(row => {
    const request = tx.objectStore(table).get(row.jobID); 
    
    request.onsuccess = async (event) => {
      const actualRow = event.target.result;
      if (actualRow) {
        // Check if newRow.updatedDate is after actualRow.updatedDate
        if (row.updatedDate > actualRow.updatedDate) {
          const putRequest = tx.objectStore(table).put(row);
          putRequest.onsuccess = () => {
            console.log('Record inserted or updated');
          };
          putRequest.onerror = (event) => {
            console.log('Error inserting or updating record', event.target.error);
          };
        } else {
          console.log('Skipping update: new row is not newer than the existing row');
        }
      } else {
        // If actualRow doesn't exist,
        const putRequest = tx.objectStore(table).put(row);
        putRequest.onsuccess = () => {
          console.log('Record inserted or updated');
        };
        putRequest.onerror = (event) => {
          console.log('Error inserting or updating record', event.target.error);
        };
      }
    };

    request.onerror = (event) => {
      console.log('Error retrieving record', event.target.error);
    };
  });

  tx.onerror = function (event) {
    console.log('error', event);
    callback('error', event);
  }

  tx.oncomplete = function (event) {
    let endTime = new Date();
    let dif = endTime.getTime() - startTime.getTime();
    dif = dif / 1000;
    console.log('IndexedDB Insert Process ran for ' + dif + ' Seconds for ' + rows.length + ' records');
    callback('success');
  }
}

// async insertBulk(table: string, rows, callback) {
//   let startTime = new Date();
//   const tx = this.db.transaction(table, 'readwrite');

//   rows.forEach(row => {
//     const request = tx.objectStore(table).put(row);
//     request.onsuccess = () => {
//       console.log('Record inserted or updated');
//     };
//     request.onerror = (event) => {
//       console.log('Error inserting or updating record', event.target.error);
//     };
//   });

//   tx.onerror = function (event) {
//     console.log('error', event)
//     callback('error', event)
//   }

//   tx.oncomplete = function (event) {
//     let endTime = new Date();
//     let dif = endTime.getTime() - startTime.getTime();
//     dif = dif / 1000;
//     console.log('IndexedDB Insert Process ran for ' + dif + ' Seconds for ' + rows.length + ' records')
//     callback('success');
//   }
// }



  async updateBulk(table: string, rows, callback) {
    let startTime = new Date();
    let status = { recordCount: 0, success: 0, fail: 0 }
    //    this.db = await this.initializeDB();
    //   this.db = await this.initializeDB().then(obj => { console.log(obj); }, err => console.log(err));

    const tx = this.db.transaction(table, 'readwrite');
    const tbl = tx.objectStore(table);


    rows.forEach(row => {
      status.recordCount++;
      let req = tbl.get(row.jobID);
      req.onsuccess = () => { tbl.put(row); status.success++; }
      req.onerror = (err) => { status.fail++; console.log('error', err) }
    })

    tx.oncomplete = (event) => {
      let endTime = new Date();
      let dif = endTime.getTime() - startTime.getTime();
      dif = dif / 1000;
      //console.log('IndexedDB Update Process ran for ' + dif + ' Seconds for ' + rows.length + ' records')
      //console.log(status)
      callback(event);
    }
  }


  deleteFromEntireDB(fileID){
    // we will try to find the file in the entire DB and erase it

    // Iterate through each object store (table) in the database
    for (var i = 0; i < this.db.objectStoreNames.length; i++) {
        var objectStoreName = this.db.objectStoreNames[i];
        var transaction = this.db.transaction(objectStoreName, 'readwrite'); // Ensure a readwrite transaction
        var objectStore = transaction.objectStore(objectStoreName);

        // Open a cursor to iterate through each record in the object store
        objectStore.openCursor().onsuccess = function(event) {
            var cursor = event.target.result;
            if (cursor) {
                // Check if the file with the specified fileID exists
                if (cursor.value.fileID === fileID) {
                    // Delete the file
                    cursor.delete();
                    console.log('File deleted from object store:', objectStoreName);
                    // You can break out of the loop or perform any other action here
                }
                cursor.continue();
            }
        };
    }

  }

  // getJobByCrewID(folderID){
  //   console.log('get getJobByCrewID with folderid => ', folderID);

  //   const transaction = this.db.transaction('jobFolders', 'readonly'); // Read-only transaction
  //   const objectStore = transaction.objectStore('jobFolders');

  //   const matchingObjects = []; 

  //   objectStore.openCursor().onsuccess = (event) => {
  //     const cursor = event.target.result; 
  //     if (cursor) {
  //       const currentRecord = cursor.value;
  //       if(currentRecord.crewFolderID.startsWith('https://drive.google.com/drive/folders/')) {
  //         currentRecord.crewFolderID = currentRecord.crewFolderID.substring('https://drive.google.com/drive/folders/'.length);
  //       }
  //       //console.log('currentRecord.crewFolderID => ', currentRecord.crewFolderID);
  //       if (currentRecord.crewFolderID === folderID) {
  //         matchingObjects.push(currentRecord);
  //       }
  //       cursor.continue(); 
  //     } else {
  //       let job = matchingObjects[0]
  //       console.log('Search completed:', job); 
  //       return job;
  //     }
  //   };

  // }

  getJobByCrewID(folderID) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction('jobFolders', 'readonly'); // Read-only transaction
      const objectStore = transaction.objectStore('jobFolders');
  
      const matchingObjects = []; 
  
      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result; 
        if (cursor) {
          const currentRecord = cursor.value;
          if(!currentRecord) return
          if(currentRecord?.crewFolderID?.startsWith('https://drive.google.com/drive/folders/')) {
            currentRecord.crewFolderID = currentRecord.crewFolderID.substring('https://drive.google.com/drive/folders/'.length);
          }
          //console.log('currentRecord.crewFolderID => ', currentRecord.crewFolderID);
          if (currentRecord?.crewFolderID === folderID) {
            matchingObjects.push(currentRecord);
          }
          cursor.continue(); 
        } else {
          let job = matchingObjects[0]
          console.log('Search completed:', job); 
          resolve(job);
        }
      };
  
      objectStore.openCursor().onerror = (event) => {
        reject(event);
      };
    });
  }
  
}
